export const _accountOverview = {
    //筛选
    search: [
        {
            type: 'el-select',
            label: '商户：',
            value: 'merchantId',
            props: {
                filterable: true,
                placeholder: '请选择商户',
                clearable: true
            },
            options: []
        },
    ],
    //列表
    table: [
        {
            prop: 'merchantName',
            label: '商户名称',
        },
        {
            prop: '_totalRecharge',
            label: '累计充值金额/元'
        },
        {
            prop: '_usedAmount',
            label: '累计扣款金额/元',
        },
        {
            prop: '_freezeAmount',
            label: '冻结金额/元',
        },
        {
            prop: '_advanceAmount',
            label: '垫付金额/元',
        },
        {
            prop: '_amountBalance',
            label: '账户余额/元',
        },
        {
            prop: '_purchasePrice',
            label: '渠道成本总金额',
        },
        {
            prop: '_statusEm',
            label: '商户状态',
        },
        {
            prop: '_openResource',
            label: '资源平台状态',
        },
        {
            label: '操作',
            width: 150,
            self: {
                body: 'operation'
            }
        }
    ],
    //状态
    status: [
        {
            label: '启用',
            value: 'NORMAL',
        },
        {
            label: '维护中',
            value: 'SUSPEND',
        },
        {
            label: '已结束',
            value: 'ENDED',
        }
    ],
};
