
import { Component, Vue } from "vue-property-decorator";
import { _accountOverview } from "@/utils/pageConfig/videovips/_accountOverview";
import { Foundation } from "@/utils/Foundation";
import recharge from "@/views/videovips/components/recharge.vue";
import refund from "@/views/videovips/components/refund.vue";
import reverseEntry from "@/views/videovips/components/reverseEntry.vue";
import Api_videoApi from "@/utils/API/videovip/videoApi";
import API_MerchantList from "@/utils/API/merchant/MerchantList";

@Component({
    components: { recharge, refund, reverseEntry },
})
export default class FinanceManage extends Vue {
    /*表格相关*/
    pageConfig: any = _accountOverview;
    tableData: any = [];
    total: number = 0;
    params: any = {
        current: 1,
        size: 20,
    };
    exportForm: any = {
        type: {
            tag: "ADMIN_RESOURCE_PLATFORM_MERCHANT_ACCOUNT",
            url: "/core/api/v1/admin/c/export/exec/resource/platform/fund/account/admin",
        },
        fileName: "账户总览",
    };

    mounted() {
        this.getTableData();
        this.getAllMerchant();
    }

    async getAllMerchant() {
        let options: any = [];
        let res: any = await Api_videoApi.getOpenresource();
        res.map((e: any) => {
            options.push({ label: e.coName, value: e.id });
        });

        this.pageConfig.search[0].options = options;
    }

    /*获取表格数据*/
    async getTableData() {
        let res: any = await Api_videoApi.fundOverview(this.params);
        this.tableData =
            (res.records &&
                res.records.length > 0 &&
                res.records.map((e: any) => {
                    e._amountBalance = Foundation.toYUAN(e.amountBalance);
                    e._advanceAmount = Foundation.toYUAN(e.advanceAmount);
                    e._usedAmount = Foundation.toYUAN(e.usedAmount);
                    e._purchasePrice = Foundation.toYUAN(e.purchasePrice);
                    e._totalRecharge = Foundation.toYUAN(e.totalRecharge);
                    e._freezeAmount = Foundation.toYUAN(e.freezeAmount);
                    e._openResource = e.openResource ? "开启" : "关闭";
                    e._statusEm =
                        (e.status != undefined &&
                            Foundation.findInArray(
                                _accountOverview.status,
                                "value",
                                e.statusEm
                            ).label) ||
                        "";
                    return e;
                })) ||
            [];
        this.total = res.total;
    }

    /*底部分页切换*/
    currentChange(pageNumber: number) {
        this.params.current = pageNumber;
        this.getTableData();
    }

    /*底部分页条数切换*/
    sizeChange(sizeNumber: number) {
        this.params.size = sizeNumber;
        this.getTableData();
    }

    /*查询*/
    handleSearch() {
        let query: any = Foundation.resetParams(
            (this.$refs.searchForm as any).form,
            true
        );
        query = Foundation.resetDate(query);
        this.exportForm.condition = query;
        this.params = {
            current: 1,
            size: 20,
            ...query,
        };
        this.getTableData();
    }

    /*冻结*/
    handleFreeze(row: any) {
        Foundation.beforeDelete(
            () => {
                Api_videoApi.platformOpen(row.merchantId, {
                    open: !row.openResource,
                }).then((res) => {
                    this.$message.success("操作成功");
                    this.getTableData();
                });
            },
            "确认要执行此操作吗",
            "操作取消"
        );
    }

    /*充值*/
    handleRecharge(data: any) {
        (this.$refs.recharge as any).show(data);
    }

    /*退款*/
    handleRefund() {
        (this.$refs.refund as any).show();
    }

    /* 冲账 */
    handleEntry(data: any) {
        (this.$refs.reverseEntry as any).show(data);
    }

    /*导出*/
    handleExport() {
        (this.$refs.exports as any).show(this.exportForm);
    }
}
