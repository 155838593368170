
import { Component, Vue } from "vue-property-decorator";
import { Foundation } from "@/utils/Foundation";
import API_MerchantList from "@/utils/API/merchant/MerchantList";
import { integer } from "@/utils/validate";
import Api_videoApi from "@/utils/API/videovip/videoApi";

@Component({
    name: "addMerchant",
})
export default class extends Vue {
    title: string = "退款";
    form: any = {
        merchantId: "",
    };
    isLoading: boolean = false;
    rules: any = {
        amount: [
            { required: true, message: "请输入冲账金额", trigger: "blur" },
        ],
        remark: [{ required: true, message: "请输入备注", trigger: "blur" }],
    };

    show(data: any = {}) {
        (this.$refs.reverseEntry as any).show();
        this.$nextTick(() => {
            this.form.merchantId = data?.merchantId;
            (this.$refs.addForm as any).clearValidate();
        });
    }

    hide() {
        (this.$refs.addForm as any).resetFields();
        (this.$refs.reverseEntry as any).hide();
    }

    /*表单提交*/
    async submitForm() {
        let form = Foundation.resetParams(this.form);

        (this.$refs.addForm as any).validate(async (valid: any) => {
            if (valid) {
                if (this.isLoading) return;
                this.isLoading = true;
                form.amount = Foundation.toMoney(form.amount);
                try {
                    await Api_videoApi.advanceReverse(form);
                    this.isLoading = false;
                    this.$message.success("操作成功");
                    this.hide();
                    this.$emit("submitForm", form);
                } catch (e) {
                    this.isLoading = false;
                }
            } else {
                return false;
            }
        });
    }
}
