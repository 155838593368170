
import { Component, Vue } from "vue-property-decorator";
import { Foundation } from "@/utils/Foundation";
import Api_videoApi from "@/utils/API/videovip/videoApi";
import { integer } from "@/utils/validate";

@Component({
    name: "addMerchant",
})
export default class extends Vue {
    title: string = "充值";
    form: any = {
        merchantName: "",
        merchantId: "",
    };
    isLoading: boolean = false;
    merchantName: string = "";
    rules: any = {
        amount: [
            { required: true, message: "请输入充值金额", trigger: "blur" },
        ],
        confirmFlag: [
            {
                required: true,
                message: "请选择第三方平台是否入账",
                trigger: "blur",
            },
        ],
        confirmAmount: [
            { required: true, message: "请输入备注入账金额", trigger: "blur" },
        ],
        advance: [
            { required: true, message: "请选择是否垫付", trigger: "blur" },
        ],
    };

    show(data: any = {}) {
        (this.$refs.recharge as any).show();
        this.$nextTick(() => {
            this.form.merchantId = data.merchantId;
            this.form.merchantName = data.merchantName;
            (this.$refs.addForm as any).clearValidate();
        });
    }

    hide() {
        (this.$refs.addForm as any).resetFields();
        (this.$refs.recharge as any).hide();
    }

    /*表单提交*/
    async submitForm() {
        let form = Foundation.resetParams(this.form);

        (this.$refs.addForm as any).validate(async (valid: any) => {
            if (valid) {
                if (this.isLoading) return;
                this.isLoading = true;
                form.amount = Foundation.toMoney(form.amount);
                if (form.advance && !form.confirmFile) {
                    this.$message.warning("请上传垫付说明凭证");
                    return;
                } else if (!form.advance && !form.confirmFile) {
                    this.$message.warning("请上传付款凭证");
                    return;
                }
                try {
                    await Api_videoApi.fundRecharge(form);
                    this.isLoading = false;
                    this.$message.success("操作成功");
                    this.hide();
                    this.$emit("submitForm", form);
                } catch (e) {
                    this.isLoading = false;
                }
            } else {
                return false;
            }
        });
    }

    /*图片上传*/
    uploadImg() {
        (this.$refs.imgChoice as any).show();
    }

    /*选择图片*/
    choiceImg(img: any) {
        this.$set(this.form, "confirmFile", img[0].ossUrl);
    }
}
